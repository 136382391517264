<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ $lang.app.archive }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit" :validation-schema="schema">

            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.motive }}</label>
              <div class="reg-step-1-select-container select-control-wrapper">
                <i class="bi-chevron-down"></i>
                <Field name="archivingMotiveId" v-slot="{ field }">
                  <Multiselect
                      v-bind="field"
                      v-model="archiveForm.archivingMotiveId"
                      :options="helper.motives"
                      :placeholder="$lang.app.select_from"
                      valueProp="id"
                      label="name"
                      :noOptionsText="$lang.app.list_empty"
                      :noResultsText="$lang.app.not_found">
                  </Multiselect>
                </Field>
              </div>
              <ErrorMessage name="archivingMotiveId" class="recovery-label-error" />
            </div>

            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.comment }}</label>
              <div>
                <Field name="message" v-slot="{ field }">
                    <textarea class="form-textarea"
                              v-bind="field"
                              v-model="archiveForm.message"
                              rows="3"/>
                </Field>
              </div>
              <ErrorMessage name="message" class="recovery-label-error" />
            </div>

            <div class="reg-step-btn">
              <button type="submit">{{ $lang.app.submit }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from "yup";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: ['applicationIds'],
  data() {
    return {
      archiveForm: {
        archivingMotiveId: '',
        message: '',
        ids: this.applicationIds,
      },
      helper: {
        motives: []
      },
      schema: yup.object({
        archivingMotiveId: yup.mixed().required(),
        message: yup.string().required(),
      }),
    }
  },
  created() {
    this.setMotives()
  },
  methods: {
    setMotives() {
      this.$axios.get(`/archiving_motive`).then(({data}) => {
        this.helper.motives = data
      })
    },
    submit() {
      this.$store.commit('startLoading')
      this.$axios.post(`/application/archive-massive`, this.archiveForm).then(() => {
        this.$snotify.success(this.$lang.app.successfully_archived);
        this.$emit('refresh')
      }).catch(error => {
        if(error.response.status === 400) {
          this.$snotify.error(error)
        }
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    }
  }
}
</script>
<style>
@import '../../../assets/styles/modal.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
@import '../../../assets/styles/multiselect.css';
</style>