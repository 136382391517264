<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ $lang.app.transfer }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit" :validation-schema="schema">

            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.groups }} <i>*</i></label>
              <div class="reg-step-1-select-container select-control-wrapper">
                <i class="bi-chevron-down"></i>
                <Field name="organizationGroupId" v-slot="{ field }">
                  <Multiselect
                      v-bind="field"
                      v-model="form.organizationGroupId"
                      :options="organizationGroups"
                      :placeholder="$lang.app.select_from"
                      valueProp="id"
                      label="title"
                      :searchable="true"
                      :noOptionsText="$lang.app.list_empty"
                      :noResultsText="$lang.app.not_found">
                  </Multiselect>
                </Field>
              </div>
              <ErrorMessage name="organizationGroupId" class="recovery-label-error" />
            </div>

            <div class="reg-step-btn">
              <button type="submit">{{ $lang.app.submit }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from "yup";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: ['organizationGroups', 'applicationIds'],
  data() {
    return {
      form: {
        organizationGroupId: null,
      },
      schema: yup.object({
        organizationGroupId: yup.mixed().required(),
      }),
    }
  },
  methods: {
    submit(values, actions) {
      this.$axios.post(`/application/mass-move/organization-group/${this.form.organizationGroupId}`, this.applicationIds)
          .then(() => {
            this.$snotify.success(this.$lang.app.successfully_transfered);
            this.$emit('refresh')
          }).catch(error => {
            if(error.response.status === 400) {
              actions.setErrors(error.response.data.errors);
            }
          })
    }
  }
}
</script>
<style>
@import '../../../assets/styles/modal.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>