<template>
  <div class="admin-list">
    <h4 class="list-title">{{ $lang.app.applications }} </h4>
    <div class="list-navbar">
      <div class="list-navbar-top">
        <div class="list-filters">
          <button v-if="$store.getters.isEmployee" class="button-default filter-button" @click.prevent="navbar.filters = !navbar.filters">
            <span>{{ $lang.app.search }}</span>
            <i v-if="!navbar.filters" class="bi-chevron-down"></i>
            <i v-else class="bi-chevron-up"></i>
          </button>
        </div>
        <div class="list-actions">
          <div class="single-actions">
            <AutoEnrollment v-if="autoEnrollable"/>
            <button v-if="preschoolDecreeable" class="button-default"
                    @click="navbar.action.preschoolDecree = true">{{ $lang.app.decree }}</button>
            <button v-if="schoolDecreeable" class="button-default"
                    @click="navbar.action.schoolDecree = true">{{ $lang.app.decree }}</button>
            <router-link v-if="$store.getters.isSchool && $route.query.status === 'ENROLLED'"
                         to="/admin/applications/create-by-children's-committee" class="button-default red">{{ $lang.app.add_child_by_kdd }}</router-link>
          </div>
          <div class="massive-actions">
            <button v-if="transferable"
                    class="button-default"
                    @click="navbar.action.transfer = true">{{ $lang.app.transfer }}</button>
<!--            <button v-if="archivable"-->
<!--                    class="button-default"-->
<!--                    @click="navbar.action.archive = true">{{ $lang.app.archive }}</button>-->
<!--            <button v-if="unarchivable"-->
<!--                    class="button-default"-->
<!--                    @click="massUnarchive">{{ $lang.app.unarchive }}</button>-->
            <button v-if="droppable"
                    class="button-default"
                    @click="navbar.action.drop = true">{{ $lang.app.drop }}</button>
            <button v-if="schoolDecreeable" class="button-default wet-asphalt"
                    @click="navbar.action.send = true">{{ $lang.app.send_to_emis }}</button>
          </div>
        </div>
      </div>
      <div v-show="navbar.filters" class="filters-content">
        <div class="filters-item filter-text">
          <div class="reg-step-form-item">
            <div class="reg-step-1-select-container select-control-wrapper">
              <input type="text" v-model="navbar.filter.search" :placeholder="$lang.app.search">
            </div>
          </div>
        </div>
        <div v-if="$store.getters.isPreschool" class="filters-item filter-select">
          <div class="reg-step-form-item">
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Multiselect
                  v-model="navbar.filter.organizationGroupId"
                  :options="navbar.helper.organizationGroups"
                  :placeholder="$lang.app.group"
                  valueProp="id"
                  label="title"
                  :searchable="true"
                  :noOptionsText="$lang.app.list_empty"
                  :noResultsText="$lang.app.not_found">
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-table">
      <div v-if="page" class="navbar-result">{{ $lang.app.total_application_quantity }}: {{ page.totalElements }}</div>
      <table>
        <thead>
        <tr>
          <th class="key">
            <div class="checkbox-wrapper">
              <input id="checkbox-all" type="checkbox" class="list-checkbox"
                     :class="{'partially-selected': (navbar.action.selectedIds.length && navbar.action.selectedIds.length !== applications.length)}"
                     :checked="navbar.action.selectedIds.length && navbar.action.selectedIds.length === applications.length"
                     @change="updateSelectedIds">
            </div>
          </th>
          <th>{{ $lang.app.application_created_date }}</th>
          <th v-if="$store.getters.isPreschool">{{ $lang.app.group }}</th>
          <th v-if="!isPreschoolWaiting">{{ $lang.app.number_or_pin }}</th>
          <th v-if="!isPreschoolWaiting">{{ $lang.app.child_full_name }}
            <div class="sort-wrapper">
              <span class="sort-item" :class="{'active': sortItemActive('children.fio', 'asc')}"
                    @click.prevent="sort('children.fio', 'asc')"><i class="bi-arrow-down"></i></span>
              <span class="sort-item" :class="{'active': sortItemActive('children.fio', 'desc')}"
                    @click.prevent="sort('children.fio', 'desc')"><i class="bi-arrow-up"></i></span>
            </div>
          </th>
          <th v-if="!isPreschoolWaiting">{{ $lang.app.birth_date }}</th>
          <th v-if="!isPreschoolWaiting">{{ $lang.app.applicant_full_name }}</th>
          <th v-if="!isPreschoolWaiting">{{ $lang.app.privilege }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="application in applications" :key="application.id" :class="{'has-privilege': hasPrivilege(application)}">
          <td class="key">
            <div class="checkbox-wrapper">
              <input :id="`checkbox-${application.id}`"
                     type="checkbox" class="list-checkbox"
                     :value="application.id" v-model="navbar.action.selectedIds">
            </div>
          </td>
          <td>{{ $moment(application.createdAt).format('DD.MM.YYYY HH:mm:ss') }}</td>
          <td v-if="$store.getters.isPreschool">
            {{ $lang.$translate({
            ru: `${application.organizationGroupName ? application.organizationGroupName + ' - ' : ''}${application.preschoolGroupNameRu}`,
            kg: `${application.organizationGroupName ? application.organizationGroupName + ' - ' : ''}${application.preschoolGroupNameKg}`
          }) }}
<!--            {{ $lang.$translate({ru: application.preschoolGroupNameRu, kg: application.preschoolGroupNameKg}) }}-->
          </td>
          <td v-if="!isPreschoolWaiting">{{ application.childPin }}</td>
          <td v-if="!isPreschoolWaiting">{{ application.childFio }}</td>
          <td v-if="!isPreschoolWaiting">{{ $moment(application.childDateOfBirth).format('DD.MM.YYYY') }}</td>
          <td v-if="!isPreschoolWaiting">{{ application.applicantFio }}</td>
          <td v-if="!isPreschoolWaiting">{{ application.applicationPrivilege }}</td>
          <td>
            <div class="action-buttons">
              <router-link v-if="viewable"
                           :to="`/admin/applications/${application.id}`"
                           class="action-button" :title="$lang.app.view">
                <i class="bi-info-square"></i>
              </router-link>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <Pagination :page="page" :links="links"/>
    </div>

    <SchoolDecreeModal v-if="navbar.action.schoolDecree"
                  @close="navbar.action.schoolDecree = false"/>

    <PreschoolDecreeModal v-if="navbar.action.preschoolDecree"
                       @close="navbar.action.preschoolDecree = false"/>

    <TransferModal v-if="navbar.action.transfer"
                    :organization-groups="navbar.helper.organizationGroups"
                    :application-ids="navbar.action.selectedIds"
                    @close="navbar.action.transfer = false"
                    @refresh="() => {
                      navbar.action.transfer = false
                      fetchResources()
                    }"/>

    <MassArchiveModal v-if="navbar.action.archive"
                      :application-ids="navbar.action.selectedIds"
                      @close="navbar.action.archive = false"
                      @refresh="() => {
                      navbar.action.archive = false
                      fetchResources()
                    }"/>

    <MassDropModal v-if="navbar.action.drop"
                      :application-ids="navbar.action.selectedIds"
                      @close="navbar.action.drop = false"
                      @refresh="() => {
                      navbar.action.drop = false
                      fetchResources()
                    }"/>
    <SendToEmisModal v-if="navbar.action.send"
                     @close="navbar.action.send = false"
                     @refresh="() => {navbar.action.send = false
                     fetchResources()
                     }"/>
  </div>
</template>
<script>
import Pagination from '../../../components/admin/Pagination'
import AutoEnrollment from "../../../components/admin/application/AutoEnrollment";
import SchoolDecreeModal from "../../../components/admin/application/SchoolDecreeModal";
import PreschoolDecreeModal from "../../../components/admin/application/PreschoolDecreeModal";
import TransferModal from "../../../components/admin/application/TransferModal";
import MassArchiveModal from "../../../components/admin/application/MassArchiveModal";
import MassDropModal from "../../../components/admin/application/MassDropModal";
import _debounce from 'lodash/debounce';
import SendToEmisModal from "@/components/admin/application/SendToEmisModal";
export default {
  components: {
    SendToEmisModal,
    Pagination,
    AutoEnrollment,
    SchoolDecreeModal,
    PreschoolDecreeModal,
    TransferModal,
    MassArchiveModal,
    MassDropModal,
  },
  data() {
    return {
      navbar: {
        filters: false,
        helper: {
          organizationGroups: [],
        },
        filter: {
          organizationGroupId: null,
          search: '',
          sort: this.$route.query.status === 'WAITING' ? [
            {
              field: 'createdAt',
              direction: 'desc',
            }
          ] : []
        },
        action: {
          schoolDecree: false,
          preschoolDecree: false,
          selectedIds: [],
          transfer: false,
          archive: false,
          drop: false,
          send: false,
        }
      },
      applications: [],
      page: null,
      links: null,
    }
  },
  computed: {
    viewable() {
      return !this.isPreschoolWaiting
    },
    isPreschoolWaiting() {
      return this.$store.getters.isPreschool && this.$route.query.status === 'WAITING'
    },
    transferable() {
      return this.$store.getters.isPreschool
          && this.navbar.action.selectedIds.length
          && (this.$route.query.status && this.$route.query.status === 'ENROLLED')
    },
    archivable() {
      return this.$store.getters.isPreschool
          && this.navbar.action.selectedIds.length
          && (this.$route.query.status && this.$route.query.status === 'ENROLLED')
    },
    droppable() {
      return this.$store.getters.isPreschool
          && this.navbar.action.selectedIds.length
          && (this.$route.query.status && this.$route.query.status === 'ENROLLED')
    },
    unarchivable() {
      return this.$store.getters.isPreschool
          && this.navbar.action.selectedIds.length
          && (this.$route.query.status && this.$route.query.status === 'ARCHIVED')
    },
    autoEnrollable() {
      return this.$store.getters.isPreschool && this.$route.query.status === 'ENROLLED'
    },
    preschoolDecreeable() {
      return this.$store.getters.isPreschool && this.$route.query.status === 'ENROLLED'
    },
    schoolDecreeable() {
      return this.$store.getters.isSchool && this.$route.query.status === 'ENROLLED'
    },
  },
  created() {
    this.setOrganizationGroups()
    this.setFilters()
    this.fetchResources()
  },
  watch: {
    'navbar.filter.organizationGroupId'(value) {
      this.$router.push({ path: '/admin/applications', query: {
        ...this.$route.query,
        organizationGroupId: value
      }})
    },
    'navbar.filter.search': _debounce(function(value) {
      this.$router.push({ path: '/admin/applications', query: {
          ...this.$route.query,
          search: value
        }})
    }, 1000),
    '$route'(value, oldValue) {
      if(value.path === oldValue.path) {
        if(!value.query.organizationGroupId) {
          this.navbar.filter.organizationGroupId = null
        }
        if(!value.query.search) {
          this.navbar.filter.search = ''
        }
        if(!value.query.sort) {
          this.navbar.filter.sort = []
        }
        this.fetchResources()
        this.navbar.action.selectedIds = []
      }
    },
    'navbar.filter.sort': {
      handler: function(value) {
        this.$router.push({ path: '/admin/applications', query: {
            ...this.$route.query,
            sort: value.map(item => `${item.field},${item.direction}`)
          }})
      },
      deep: true
    }
  },
  methods: {
    sortItemActive(field, direction) {
      return this.navbar.filter.sort.findIndex(item => item.field === field && item.direction === direction) >= 0
    },
    sort(field, direction) {
      let index = this.navbar.filter.sort.findIndex(item => item.field === field )
      if(index >= 0) {
        if(this.navbar.filter.sort[index].direction === direction) {
          this.navbar.filter.sort.splice(index, 1);
        } else {
          this.navbar.filter.sort[index].direction = direction
        }
      } else {
        this.navbar.filter.sort.push({field, direction})
      }
    },
    setFilters() {
      if(this.$route.query.organizationGroupId) {
        this.navbar.filter.organizationGroupId = this.$route.query.organizationGroupId
      }
      if(this.$route.query.search) {
        this.navbar.filter.search = this.$route.query.search
      }
      if(Array.isArray(this.$route.query.sort)) {
        this.navbar.filter.sort = this.$route.query.sort.map(item => {
          return {
            field: item.split(',')[0],
            direction: item.split(',')[1]
          }
        })
      } else if(this.$route.query.sort) {
        this.navbar.filter.sort.push({
          field: this.$route.query.sort.split(',')[0],
          direction: this.$route.query.sort.split(',')[1]
        })
      }
    },
    updateSelectedIds() {
      this.navbar.action.selectedIds = this.navbar.action.selectedIds.length ? [] : this.applications.map(app => app.id)
    },
    hasPrivilege(application) {
      return (application.applicantPrivilege && application.applicantPrivilege !== 200)
          || (application.childPrivilege && application.childPrivilege !== 1)
    },
    setOrganizationGroups() {
      if(this.$store.getters.isPreschool) {
        this.$axios.get(`/organization-group/organization/${this.$store.getters.getOrganizationId}`).then(({data}) => {
          this.navbar.helper.organizationGroups = data.map(item => ({
            id: item.id,
            title: (item.name ? item.name + ' - ' : '') + this.$lang.$translate({
              ru: item.preschoolGroupType ? item.preschoolGroupType.nameRu : '',
              kg: item.preschoolGroupType ? item.preschoolGroupType.nameKg : ''})
          }))
        })
      }
    },
    massUnarchive() {
      this.$store.commit('startLoading')
      this.$axios.post(`/application/unarchive-massive`,
        this.navbar.action.selectedIds
      ).then(() => {
        this.$snotify.success(this.$lang.app.successfully_unarchived);
        this.fetchResources()
      }).catch(error => {
        if(error.response.status === 400) {
          this.$snotify.error(error)
        }
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    },
    getApplicantApplications() {
      this.$axios.get(`/application/list/applicant/${this.$store.getters.getPersonId}`,
          { params: this.$route.query }
      ).then(({data}) => {
        this.applications = data._embedded ? data._embedded.applicationResourceList : []
        this.page = data.page
        this.links = data._links
      })
    },
    getPreschoolArchivedApplications() {
      this.$store.commit('startLoading')
      this.$axios.get(`/application/list/preschool/${this.$store.getters.getOrganizationId}/archive`,
          { params: this.$route.query }
      ).then(({data}) => {
        this.applications = data._embedded ? data._embedded.preschoolApplicationResourceList : []
        this.page = data.page
        this.links = data._links
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    },
    getPreschoolApplications() {
      this.$store.commit('startLoading')
      this.$axios.get(`/application/list/preschool/${this.$store.getters.getOrganizationId}/status/${this.$route.query.status}`,
          {
            params: {
              ...this.$route.query,
              size: (this.$store.getters.isPreschool
                  && (this.$route.query.status === 'ENROLLED' || this.$route.query.status === 'WAITING'))
                  ? 100 : 20,
            }
          }
      ).then(({data}) => {
        this.applications = data._embedded ? data._embedded.preschoolApplicationResourceList : []
        this.page = data.page
        this.links = data._links
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    },
    getSchoolApplications() {
      this.$store.commit('startLoading')
      this.$axios.get(`/application/list/organization/${this.$store.getters.getOrganizationId}/status/${this.$route.query.status}`,
          { params: this.$route.query }
      ).then(({data}) => {
        this.applications = data._embedded ? data._embedded.applicationResourceList : []
        this.page = data.page
        this.links = data._links
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    },
    fetchResources() {
      if(this.$store.getters.isParent) {
        this.getApplicantApplications()
      }
      if(this.$store.getters.isPreschool) {
        if(this.$route.query.status === 'ARCHIVED') {
          this.getPreschoolArchivedApplications()
        } else {
          this.getPreschoolApplications()
        }
      }
      if(this.$store.getters.isSchool) {
        this.getSchoolApplications()
      }
    },
  },
}
</script>
<style>
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/table.css';
@import '../../../assets/styles/form.css';
@import '../../../assets/styles/multiselect.css';
@import '../../../assets/styles/admin/list-navbar.css';
.has-privilege {
  background: lemonchiffon;
}
.red {
  background: #AE2025;
  color: white;
}
</style>