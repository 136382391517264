<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="print-modal-content">
      <div class="print-modal-header">
        <h4 class="print-modal-title">{{ $lang.app.decree }}</h4>
        <button class="print-button" @click="print">{{ $lang.app.print }}</button>
      </div>
      <div class="print-content-wrapper">
        <div id="printContent" class="print-content">
          <div class="page-header">
            <div class="header-left">
              <h3 class="ministry-name">КЫРГЫЗ <br> РЕСПУБЛИКАСЫНЫН <br> БИЛИМ БЕРҮҮ ЖАНА ИЛИМ <br> МИНИСТРЛИГИ</h3>
              <h5 v-if="organization" class="organization-name">{{ organization.nameKG }}</h5>
            </div>
            <div class="header-img-wrapper">
              <img class="header-img" src="/gerb.png">
            </div>
            <div class="header-right">
              <h3 class="ministry-name">МИНИСТЕРСТВО <br> ОБРАЗОВАНИЯ И НАУКИ <br> КЫРГЫЗСКОЙ <br> РЕСПУБЛИКИ</h3>
              <h5 v-if="organization" class="organization-name">{{ organization.nameRU }}</h5>
            </div>
          </div>
          <h2 class="doc-title">Буйрук <br> Приказ</h2>
          <div class="doc-info">
            <div class="doc-date">20__-ж. «____»-___________ № _______ <br> «____» ___________ 20__ г № _______</div>
          </div>
          <div class="doc-content">
            <h4 class="doc-subtitle">Мектепке чейинки билим берүү уюмуна кабыл алуу жөнүндө <br>
              О принятии в дошкольную образовательную организацию</h4>
            <p class="doc-text">Автоматташтырылган маалыматтык тутум аркылуу электрондук кезекке катталган балдарды
              мектепке чейин билим берүү уюмуна кабыл алуу максаттарында, <b>буйрук кылам:</b></p>
            <p class="doc-text">Мектепке чейин билим берүү уюмуна балдар ____ тиркемеге ылайык кабыл алынышсын.</p>
          </div>
          <div class="doc-content">
            <p class="doc-text">В целях приема детей в дошкольную образовательную организацию, записанных в электронную
              очередь через автоматизированную информационную систему, <b>приказываю:</b></p>
            <p class="doc-text">Принять детей в дошкольную образовательную организацию согласно приложению № ____.</p>
          </div>
          <div class="doc-owner">
            <div><b>Директор</b></div>
            <div><b>{{ fullname }}</b></div>
          </div>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>

export default {
  data() {
    return {
      organization: null
    }
  },
  computed: {
    fullname() {
      let firstNameCapital = this.$store.getters.getUser.firstName ? this.$store.getters.getUser.firstName[0] : null
      let middleNameCapital = this.$store.getters.getUser.middleName ? this.$store.getters.getUser.middleName[0] : null
      let lastName = this.$store.getters.getUser.lastName ? this.$store.getters.getUser.lastName : ''
      return (firstNameCapital ? firstNameCapital + '.' : '') +
          (middleNameCapital ? middleNameCapital + '.' : '') + lastName
    }
  },
  created() {
    this.setOrganization()
  },
  methods: {
    setOrganization() {
      this.$axios.get(`/organization/${this.$store.getters.getOrganizationId}`)
          .then(({data}) => {
            this.organization = data
          })
    },
    print() {
      let win = window.open('','','left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0');

      let content = "<html><style>@page { size: auto;  margin: 2.0cm 1.7cm 2.0cm 2.5cm } .print-content {background: white;box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);font-family: \"Times New Roman\";font-size: 14px;padding: 40px 30px 40px 50px;}  .page-header {display: flex;align-items: flex-start;margin-bottom: 72px;}  .header-left, .header-right {width: 33.33%;}  .ministry-name {text-align: center;font-size: 12px;font-weight: bold;line-height: 1.2;text-transform: uppercase;}  .organization-name {text-align: center;font-size: 12px;font-weight: bold;line-height: 1.2;}  .header-img-wrapper {width: 33.33%;display: flex;justify-content: center;align-items: center;}  .header-img {width: 35%;}  .doc-title {font-weight: bold;text-align: center;text-transform: uppercase;letter-spacing: 3px;margin-bottom: 12px;font-size: 14px;}  .doc-info {font-size: 14px;display: flex;justify-content: space-between;margin-bottom: 36px;}  .doc-content {margin-bottom: 40px;}  .doc-subtitle {font-size: 14px;font-weight: bold;text-align: center;margin-bottom: 20px;}  .doc-text {text-indent: 2rem;text-align: justify;margin-bottom: 0;}  .doc-owner {display: flex;justify-content: space-between;margin-bottom: 60px;} </style>";

      content += "<body onload=\"window.print(); window.close();\">";
      content += document.getElementById("printContent").innerHTML ;
      content += "</body>";
      content += "</html>";
      win.document.write(content);
      win.document.close();
    }
  }
}
</script>
<style scoped>
@import '../../../assets/styles/admin/print-modal.css';

.print-content {
  background: white;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  font-family: "Times New Roman";
  font-size: 14px;
  padding: 40px 30px 40px 50px;
}

.page-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 72px;
}

.header-left, .header-right {
  width: 33.33%;
}

.ministry-name {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
}

.organization-name {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
}

.header-img-wrapper {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-img {
  width: 35%;
}

.doc-title {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 12px;
  font-size: 14px;
}

.doc-info {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}

.doc-content {
  margin-bottom: 40px;
}

.doc-subtitle {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.doc-text {
  text-indent: 2rem;
  text-align: justify;
  margin-bottom: 0;
}

.doc-owner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
</style>