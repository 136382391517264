<template>
  <div class="auto-enrollment">
    <div class="auto-enrollment-label">{{ $lang.app.auto_enrollment }}</div>
    <label class="switch" @click="toggleEnrollment">
      <input type="checkbox" disabled
             :checked="$store.getters.getAutoEnrollment">
      <span class="slider round"></span>
    </label>
  </div>
</template>
<script>
export default {
  methods: {
    toggleEnrollment() {
      if(!this.$store.getters.getAutoEnrollment) {
        this.$swal.fire({
          icon: 'warning',
          text: this.$lang.app.auto_enrollment_confirm_question,
          showDenyButton: true,
          confirmButtonColor: '#4c9ac7',
          denyButtonColor: '#6e7881',
          confirmButtonText: this.$lang.app.yes,
          denyButtonText: this.$lang.app.no,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.commit('startLoading')
            this.$axios.get(`/application/organization/${this.$store.getters.getOrganizationId}/auto`,
                { params: { enrollment: true } })
                .then(() => {
                  this.$store.commit('setAutoEnrollment', true)
                  this.$snotify.success(this.$lang.app.auto_enrollment_on);
                }).catch(error => {
                  this.$snotify.error(error);
                }).finally(() => {
                  this.$store.commit('stopLoading')
                })
          } else if(result.isDenied) {
            console.log('deny')
          }
        })
      } else {
        this.$store.commit('startLoading')
        this.$axios.get(`/application/organization/${this.$store.getters.getOrganizationId}/auto`,
            { params: { enrollment: false } })
            .then(() => {
              this.$store.commit('setAutoEnrollment', false)
              this.$snotify.success(this.$lang.app.auto_enrollment_off);
            }).catch(error => {
              this.$snotify.error(error);
            }).finally(() => {
              this.$store.commit('stopLoading')
            })
      }
    }
  },
}
</script>
<style>
@import '../../../assets/styles/switch.css';
.auto-enrollment {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.auto-enrollment-label {
  margin-right: 10px;
}
</style>
