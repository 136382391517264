<template>
  <div>
    <div v-if="page && page.totalPages > 1" class="pagination">
      <div class="pagination-page">
        <div>Показано {{ fromItem }}-{{ toItem }} из {{ page.totalElements }} записей</div>
      </div>
      <div v-if="links" class="pagination-links">
        <button v-if="links.first" @click="url(links.first.href)"><i class="bi-chevron-double-left"></i></button>
        <button v-if="links.prev" @click="url(links.prev.href)"><i class="bi-chevron-left"></i></button>
        <span class="current">{{ page.number + 1 }}</span>
        <button v-if="links.next" @click="url(links.next.href)"><i class="bi-chevron-right"></i></button>
        <button v-if="links.last" @click="url(links.last.href)"><i class="bi-chevron-double-right"></i></button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['page', 'links'],
  computed: {
    fromItem() {
      return this.page.size * this.page.number + 1
    },
    toItem() {
      return this.page.totalElements < this.page.size * (this.page.number + 1) ? this.page.totalElements : this.page.size * (this.page.number + 1)
    },
  },
  methods: {
    url(href) {
      let url = new URL(href)

      for (let [key, value] of Object.entries(this.$route.query)) {
        if(!url.searchParams.has(key)) {
          url.searchParams.append(key, value)
        }
      }

      this.$router.push(this.$route.path + '?' + url.searchParams.toString())
    }
  }
}
</script>
<style>
.pagination {
  margin: 20px 0 5px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination-links button {
  border: 1px solid lightgray;
  border-radius: 2px;
  width: 30px;
  height: 30px;
  margin: 0 2px;
  background: none;
}
.pagination-links .current {
  margin: 0 10px;
  font-weight: bold;
}
</style>