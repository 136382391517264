<template>
  <div class="modal">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <h4 class="create-title">{{ $lang.app.send_to_emis_title }}</h4>
      <div class="admin-form-wrapper">
        <div class="admin-form">
          <Form @submit="submit" :validation-schema="schema">

            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.send_to_emis_label }}</label>
              <div>
                <Field name="message" v-slot="{ field }">
                    <textarea class="form-textarea"
                              v-bind="field"
                              v-model="sendForm.message"
                              rows="3"/>
                </Field>
              </div>
              <ErrorMessage name="message" class="recovery-label-error"/>
            </div>

            <div class="reg-step-btn">
              <button type="button" class="button-red" @click="$emit('close')">{{ $lang.app.emis_no }}</button>
              <button type="submit">{{ $lang.app.emis_yes }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <button class="modal-close" @click="$emit('close')"><i class="bi-x-circle"></i></button>
  </div>
</template>
<script>
import {Field, Form, ErrorMessage} from 'vee-validate';
import * as yup from "yup";

export default {
  // methods: this.$axios.get(`/application/organization/${this.$store.getters.getOrganizationId}/send`)
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      sendForm: {
        message: '',
      },
      schema: yup.object({
        message: yup.string().required(),
      }),
    }
  },
  methods: {
    submit(values, actions) {
      this.$axios.post(`/application/organization/${this.$store.getters.getOrganizationId}/send`, this.sendForm).then(({data}) => {
        if (data.success) {
          // let send = data.data.send === 'undefined' ? data.data.send : 0;
          // let enrolled = data.data.enrolled === 'undefined' ? data.data.enrolled : 0;
          this.$snotify.success( this.$lang.app.get_on_message + ' ' +  data.data.enrolled + ' ' + this.$lang.app.from_message + ' ' + data.data.send)
        }
        else {
          this.$snotify.error(data.message)
        }
        this.$emit('refresh')
      }).catch(error => {
        if (error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      })
    }
  }
}
</script>
<style>
@import '../../../assets/styles/switch.css';

.button-default.wet-asphalt {
  background: #213241;
  color: white;
}
</style>
